:root {
  --white: #ffffff;
  --primary: #313D53;
}

.heroTitle {
  color: inherit;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .heroTitle {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
  }
}
@media (min-width: 1366px) {
  .heroTitle {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.125;
  }
}

.heroSubtitle {
  color: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  margin: 0;
}
@media (min-width: 768px) {
  .heroSubtitle {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 16px;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 1365px) {
  .heroSubtitle {
    max-width: 344px;
  }
}
@media (max-width: 767px) {
  .heroSubtitle {
    max-width: 311px;
  }
}

.heroBox {
  --size: 408px;
  --gap: 16px;
  color: var(--white);
  width: var(--size);
  gap: var(--gap);
  flex-basis: var(--size) !important;
}
@media (min-width: 960px) {
  .heroBox {
    --gap: 40px;
  }
}
@media (min-width: 1366px) {
  .heroBox {
    --size: 488px;
  }
}
@media (max-width: 599px) {
  .heroBox {
    --size: 298px;
  }
}

.heroContainer {
  justify-content: space-between;
}

@media (max-width: 767px) {
  .registerTitle {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    color: var(--primary);
    margin: 0;
  }
}/*# sourceMappingURL=hero.css.map */