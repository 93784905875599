@mixin responsive($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }

    @if str-slice($breakpoint, 0, 1) == ">" and map-has-key($breakpoints, str-slice($breakpoint, 2, -1)) {
        @media (min-width: #{map-get($breakpoints, str-slice($breakpoint, 2, -1))}) {
            @content;
        }
    }

    @if str-slice($breakpoint, 0, 1) == "<" and map-has-key($breakpoints, str-slice($breakpoint, 2, -1)) {
        @media (max-width: #{map-get($breakpoints, str-slice($breakpoint, 2, -1)) - 1px} ) {
            @content;
        }
    }
}

//typography
@mixin DisplayXL_SB {
    font:{
        size: rem(64);
        weight: 600;
    }
    line-height: line_height(72,64);
}

@mixin DisplayLarge_SB {
    font:{
        size: rem(40);
        weight: 600;
    }
    line-height: line_height(48,40);
}

@mixin DisplayMedium_SB {
    font:{
        size: rem(32);
        weight: 600;
    }
    line-height: line_height(40,32);
}

@mixin BodyLargeR {
    font:{
        size: rem(20);
        weight: 300;
    }
    line-height: line_height(28,20);
}

@mixin BodySmallR {
    font:{
        size: rem(14);
        weight: 400;
    }
    line-height: line_height(20,14);
}

@mixin HeadingLargeB {
    font:{
        size: rem(20);
        weight: 700;
    }
    line-height: line_height(24,20);
}