@import '../starter';
.heroTitle {
    color: inherit;
    margin: 0;
    @include DisplayMedium_SB();
    @include responsive(">medium") {
        @include DisplayLarge_SB();
    }
    @include responsive(">xxlarge") {
        @include DisplayXL_SB();
    }
}

.heroSubtitle {
    color: inherit;
    @include BodySmallR();
    margin: 0;
    @include responsive(">medium") {
        @include  BodyLargeR();
        margin-bottom: 16px;
        letter-spacing: -0.02em;
    }
    @include responsive("<xxlarge") {
        max-width: 344px;
    }
    @include responsive("<medium") {
        max-width: 311px;
    }
}

.heroBox {
    --size: 408px;
    --gap: 16px;
    color: var(--white);
    @include responsive(">large") {
        --gap: 40px;
    }
    @include responsive(">xxlarge") {
        --size: 488px;
    }
    @include responsive("<small") {
        --size: 298px;
    }
    width: var(--size);
    gap: var(--gap);
    flex-basis: var(--size) !important;
}

.heroContainer {
    justify-content: space-between;
}

.registerTitle {
    @include responsive("<medium") {
        @include HeadingLargeB();
        color: var(--primary);
        margin: 0;
    }
}