:root {
    --scrollbar-color-thumb: #5E77A1;
    --scrollbar-color-track: #dcdfe4;
    --scrollbar-width: thin;
    --scrollbar-width-legacy: 4px;
}

html {
    font-family: 'Manrope', sans-serif;
}

p {
    font-size: initial;
    font-weight: initial;
    margin: 0;
}

::selection {
    background-color: #313D53;
    color: #EAECEE;
    -webkit-text-fill-color: #EAECEE;
}

/* @media only screen and (min-width: 768px) and (min-height: 600px) {
    :host {
        --border-radius: 16px;
    }
} */


html.plt-mobile ion-app {
    user-select: auto;
}

@media only screen and (max-width:960px) {
    [class*='MuiSnackbar-root'] {
        bottom: 88px !important;
    }
}

.MuiPickersCalendarHeader-label {
    font-size: 18px;
    color: #1D2532;
}

.MuiPickersYear-yearButton:not(.Mui-selected) {
    color: #1D2532 !important;
}

.MuiPickersYear-yearButton.Mui-selected {
    color: #ffffff !important;
}

.mobMenuLink,
.mobMenuLink:link,
.mobMenuLink:visited {
    text-decoration: none;
    color: #313D53;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobMenuLink__icon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.noStyleList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.socialList,
.socialList li {
    line-height: 1;
}

.socialLink,
.socialLink:link,
.socialLink:visited {
    display: inline-block;
    line-height: 1;
}

.appLink,
.appLink:link,
.appLink:visited {
    display: inline-block;
    text-decoration: none;
    line-height: 1;
}

.logoLink,
.logoLink:link,
.logoLink:visited {
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    vertical-align: middle;
}

.iconLink,
.iconLink:link,
.iconLink:visited {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0;
    color: #1D2532;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
}

.iconLink--right,
.iconLink--right:link,
.iconLink--right:visited {
    min-width: 24px;
    min-height: 24px;
    justify-content: flex-end;
}

.iconLink--small,
.iconLink--small:link,
.iconLink--small:visited {
    align-items: flex-end;
    justify-content: space-between;
}

.boxLink,
.boxLink:link,
.boxLink:visited {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    padding: 32px;
}

.btnLink,
.btnLink:link,
.btnLink:visited {
    --focus: currentColor;
    user-select: none;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    padding: 6px 8px;
    text-decoration: none;
    line-height: 1;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 500;
    color: #1D2532;
    border-radius: 4px;
}

.btnLink:hover {
    text-decoration: none;
    background-color: rgba(49, 61, 83, 0.04);
}

.btnLink:focus {
    outline: 0;
}

.btnLink:focus-visible {
    outline: 2px dashed var(--focus);
    outline-offset: 2px;
}

.btnLink--underline,
.btnLink--underline:link,
.btnLink--underline:visited {
    background-color: transparent;
    text-decoration: underline;
    text-underline-offset: 5px;
    white-space: nowrap;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
}

.btnLink--blue,
.btnLink--blue:link,
.btnLink--blue:visited {
    color: #3C68C8;
}

.btnLink--icon,
.btnLink--icon:link,
.btnLink--icon:visited {
    gap: 8px;
    font-size: 14px;
    line-height: 1.42;
    color: #313D53;
}

.btnLink--menu,
.btnLink--menu:link,
.btnLink--menu:visited {
    text-align: left;
    padding: 6px;
    font-size: 16px;
    line-height: 1.5;
    color: #313D53;
    justify-content: flex-start;
    letter-spacing: normal;
}

.btnLink--small,
.btnLink--small:link,
.btnLink--small:visited {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    text-decoration-color: #E9688A;
    text-underline-offset: 3px;
}

.btnLink--small:hover {
    background-color: rgba(49, 61, 83, 0.04);
    text-decoration: none;
}

.btnLink--xs,
.btnLink--xs:link,
.btnLink--xs:visited {
    color: #313D53;
    text-decoration-color: #FCE5D8;
    text-underline-offset: 0;
}

.btnLink--result,
.btnLink--result:link,
.btnLink--result:visited {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42;
    color: #313D53;
    padding: 6px;
    margin: 0;
    min-width: inherit;
}

.btnLink--table,
.btnLink--table:link,
.btnLink--table:visited {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0;
    color: #313D53;
    text-decoration: underline;
    text-decoration-color: #FCE5D8;
    text-underline-offset: 5px;
    display: inline-block;
}
.btnLink--table:hover {
    text-decoration: none;
    background-color: rgba(49, 61, 83, 0.04);
}
.btnLink--table:focus {
    outline: 0;
}

.btnLink--table:focus-visible {
    outline: 2px dashed currentColor;
}
.iconBtn {
    transition: opacity .25s ease-out;
}
.iconBtn:hover {
    opacity: .7;
    background-color: inherit !important;
    /* background: rgba(0, 0, 0, 0.08) !important; */
}
.iconBtn:active {
    opacity: .5;
    /* background: rgba(0, 0, 0, 0.12) !important; */
}
.iconBtn:focus {
    outline: 0;
}
.iconBtn:focus-visible {
    outline: 2px solid rgba(60, 104, 200, 0.5);
}

.iconBtn--sm,
.iconBtn--sm:link,
.iconBtn--sm:visited {
    box-shadow: none;
    width: 24px;
    min-width: 24px !important;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}

.topbarLink {
    position: relative;
}

.topbarLink:before {
    content: '';
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    top: -4px;
    left: -4px;
    opacity: 0;
    transition: opacity .25s ease-out;
}

.topbarLink:hover:before,
.topbarLink:active:before {
    opacity: 1;
}

.topbarLink:focus {
    outline: 0;
}
.topbarLink:focus-visible {
    outline: 2px solid rgba(60, 104, 200, 0.5);
}

.link,
.link:link,
.link:visited {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3C68C8;
    text-underline-offset: 4px;
    text-decoration: underline;
    text-decoration-color: rgba(60, 104, 200, 0.4);
}


.linkMob,
.linkMob:link,
.linkMob:visited {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #3C68C8;
    text-underline-offset: 4px;
    text-decoration: underline;
    text-decoration-color: rgba(60, 104, 200, 0.4);
}

.linkMob--blue,
.linkMob--blue:link,
.linkMob--blue:visited {
    text-decoration-color: #3C68C8;
    letter-spacing: normal;
}

.pigogoButton,
.pigogoButton:link,
.pigogoButton:visited {
    --text: #fff;
    --focus: #2C374B;
    --hover: #1D2532;
    --background: #313D53;
    --disabled: rgba(0, 0, 0, 0.12);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: 0;
    border: 0;
    margin: 0;
    user-select: none;
    min-width: 64px;
    color: var(--text);
    border-radius: 0px 32px 32px 32px;
    background: var(--background);
    padding: 16px;
    box-shadow: none;
    box-sizing: border-box;
    height: 56px;
    transition: background .35s ease;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

.pigogoButton:hover {
    box-shadow: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), var(--hover);
}

.pigogoButton:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), var(--focus);
}

.pigogoButton:focus {
    outline: 0;
    background: var(--focus);
}

.pigogoButton:disabled {
    background: var(--disabled);
}

.pigogoButton--disabled,
.pigogoButton--disabled:link,
.pigogoButton--disabled:visited {
    background: var(--disabled);
    pointer-events: none;
}

.pigogoButton:focus-visible {
    background: var(--focus);
    outline: 2px dashed var(--focus);
    outline-offset: 2px;
}

.pigogoButton--fullwidth,
.pigogoButton--fullwidth:link,
.pigogoButton--fullwidth:visited {
    width: 100%;
}

.pigogoButton--secondary,
.pigogoButton--secondary:link,
.pigogoButton--secondary:visited {
    --text: #fff;
    --focus: #E9688A;
    --hover: #E9688A;
    --background: #E9688A;
    --disabled: rgba(0, 0, 0, 0.12);
}

.pigogoButton--white,
.pigogoButton--white:link,
.pigogoButton--white:visited {
    --focus: #fff;
    --hover: #fff;
    --text: #E9688A;
    --background: #fff;
    --disabled: rgba(255, 255, 255, 0.12);
}

.pigogoButton--outlined,
.pigogoButton--outlined:link,
.pigogoButton--outlined:visited {
    color: var(--background);
    border: 1px solid var(--background);
    background: none;
}

.pigogoButton--outlined:hover {
    background: rgba(0, 0, 0, 0.04);
}

.pigogoButton--outlined:active {
    background: rgba(0, 0, 0, 0.12);
}

.pigogoButton--outlined:focus {
    box-shadow: 0 0 0 3px rgba(60, 104, 200, 0.5);
    border: 1px solid var(--background);
}

.pigogoButton--outlined:disabled {
    color: rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.pigogoButton--icon,
.pigogoButton--icon:link,
.pigogoButton--icon:visited {
    gap: 10px;
}

.pigogoButton--rounded,
.pigogoButton--rounded:link,
.pigogoButton--rounded:visited {
    border-radius: 32px;
}

.pigogoButton--small,
.pigogoButton--small:link,
.pigogoButton--small:visited {
    font-size: 14px;
    font-weight: 500;
    padding-inline: 8px;
}

.absLink {
    position: absolute;
    inset: 0;
    z-index: 2;
}

.absLink:hover ~ .border-hover {
    border-color: #F7BA97;
}

.suggestedLink {
    inset: 0;
    z-index: 2;
    text-decoration-line: none;
}

.accountLink,
.accountLink:link,
.accountLink:visited,
.sidebarBtn,
.sidebarBtn:link,
.sidebarBtn:visited {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;
    color: #313d53;
    text-decoration: none;
    background-color: transparent;
    text-align: left;
}

.accountLink,
.accountLink:link,
.accountLink:visited {
    padding: 6px 0;
    border-radius: 4px;
}

.sidebarBtn,
.sidebarBtn:link,
.sidebarBtn:visited {
    padding: 0;
}

.sidebarBtn:not(.tafBtn) {
    position: relative;
}

.sidebarBtn:not(.tafBtn):before {
    content: '';
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -8px;
    position: absolute;
    background-color: rgba(49, 61, 83, 0.04);
    opacity: 0;
    transition: opacity .25s ease-out;
}

@media only screen and (max-width: 959px) {
    .sidebarBtn,
    .sidebarBtn:link,
    .sidebarBtn:visited,
    .sidebarBtn span {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 599.95px) {
    .sidebarBtn,
    .sidebarBtn:link,
    .sidebarBtn:visited {
        font-size: 16px;
        font-weight: 700;
    }
}

.tafBtn,
.tafBtn:link,
.tafBtn:visited {
    color: #E9688A;
    transition: padding-inline .2s ease;
    border-radius: 8px;
    height: 40px;
    margin-block: -8px;
}

.accountLink:hover {
    background-color: rgba(49, 61, 83, 0.04);
}

.sidebarBtn:not(.tafBtn):hover {
    /* color: #E9688A; */
    /* background-color: rgba(49, 61, 83, 0.04); */
    background-color: transparent;
}
.sidebarBtn:not(.tafBtn):hover:before {
    opacity: 1;
}
.tafBtn:hover {
    padding-inline: 8px;
    background-color: rgba(233, 104, 138, 0.04);
}
.accountLink:focus,
.sidebarBtn:focus {
    outline: 0;
}
.accountLink:focus-visible,
.sidebarBtn:focus-visible {
    outline: 2px dashed currentColor;
    outline-offset: 2px;
}
.accountLink--mTop {
    margin-top: -6px;
}
.accountLink--disabled {
    pointer-events: none;
}

.storeCardBox {
    position: relative;
}

.storeCardBox button,
.storeCardBox [role="button"] {
    position: relative;
    z-index: 3;
}

.storeCard {
    --focus: #313D53;
    display: block;
    width: 100%;
    text-decoration: none;
}
.storeCard:focus {
    outline: 0;
}

.storeCard:focus-visible {
    outline: 2px dashed var(--focus);
    outline-offset: 2px;
}

.storeCard--abs {
    position: absolute;
    inset: 0;
    z-index: 2;
}

.storeCard--abs ~ .couponCard {
    z-index: 2;
    pointer-events: none;
}

.storeCard--abs:hover ~ .couponCard {
    background-color: #FBE1E8;
}

.storeCard--abs ~ .couponCard .pigogoChip {
    position: relative;
    z-index: 444;
    pointer-events: all;
}

.storeCard--offer {
    height: 100%;
}

.footerLink,
.footerLink:link,
.footerLink:visited {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    text-decoration: none;
    display: inline-block;
    color: #313D53;
}

.copylinkList {
    display: flex;
    gap: 28px;
}

.copyrightLink,
.copyrightLink:link,
.copyrightLink:visited {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: #313D53;
    text-decoration: underline;
    text-decoration-color: #E9688A;
    text-underline-offset: 3px;
}

.footerNav__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.heroCarousel,
.heroCarouselVertical {
    position: relative;
}
.heroCarouselVertical {
    .control-arrow {
        all: unset;
        position: absolute;
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 4px !important;
        border-radius: 50%;
        opacity: 1 !important;
        top: 50% !important;
        bottom: auto !important;
        margin-top: -16px !important;
        &::before {
            border: 0 !important;
        }
        &.control-prev {
            content: url(assets/svgs/arrowUp.svg);
            transform: translateY(-72px);
        }
        &.control-next {
            content: url(assets/svgs/arrowDown.svg);
            transform: translateY(80px);
        }
    }
}
@media only screen and (min-width: 1280px) {
    .heroCarouselVertical {
        .control-arrow {
            &.control-prev {
                left: auto !important;
                right: 697px !important;
            }
            &.control-next {
                left: auto !important;
                right: 697px !important;
            }
        }
    }
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
    .heroCarouselVertical {
        .control-arrow {
            &.control-prev {
                left: 24px !important;
                right: auto !important;
            }
            &.control-next {
                left: 24px !important;
                right: auto !important;
            }
        }
    }
}
@media only screen and (min-width: 960px) {
    .heroCarousel .react-multi-carousel-dot-list {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        right: 696px;
        left: auto;
        flex-direction: column;
    }

    .hero--loading .react-multi-carousel-dot-list {
        opacity: 0;
    }

    .hero--loading .react-multi-carousel-list {
        justify-content: flex-end;
        margin-right: 108px;
    }

    
    
    @media only screen and (max-width: 1279px) {
        .heroCarousel .react-multi-carousel-dot-list {
            right: auto;
            left: 24px;
        }
    }
}


@media only screen and (max-width: 959px) {
    .heroCarousel .react-multi-carousel-dot-list {
        bottom: 40px;
    }
}

.hero--loading {
    background: linear-gradient(0deg, rgba(49, 61, 83, 1) 0%, rgba(49, 61, 83, 1) 100%), linear-gradient(99deg, rgba(16, 31, 75, 1) 0%, rgba(233, 104, 138, 1) 100%);
}

.heroPromoCard--loading {
    justify-content: center;
    height: 100%;
}

/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
    body, .overscroll, .MuiMenu-list {
        scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track) !important;
        scrollbar-width: var(--scrollbar-width) !important;
    }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
    .overscroll::-webkit-scrollbar-thumb,
    .MuiMenu-list::-webkit-scrollbar-thumb,
    body::-webkit-scrollbar-thumb {
        background: var(--scrollbar-color-thumb) !important;
    }
    .overscroll::-webkit-scrollbar-track,
    .MuiMenu-list::-webkit-scrollbar-track,
    body::-webkit-scrollbar-track {
        background: var(--scrollbar-color-track) !important;
    }
    .overscroll::-webkit-scrollbar,
    .MuiMenu-list::-webkit-scrollbar,
    body::-webkit-scrollbar {
        max-width: var(--scrollbar-width-legacy) !important;
        max-height: var(--scrollbar-width-legacy) !important;
    }
    /* body::-webkit-scrollbar {
        width: inherit;
    }
    body::-webkit-scrollbar-thumb {
        background-color: inherit;
        outline: inherit;
        border-radius: inherit;
    }
    body::-webkit-scrollbar-track {
        box-shadow: inherit;
    } */
}

/* .overscroll::-webkit-scrollbar,
.MuiMenu-list::-webkit-scrollbar {
    width: 4px;
}


.overscroll::-webkit-scrollbar-thumb,
.MuiMenu-list::-webkit-scrollbar-thumb {
    background-color: #5E77A1;
    outline: 1px solid #5E77A1;
    border-radius: 50px;
}

.overscroll:hover::-webkit-scrollbar-thumb,
.MuiMenu-list:hover::-webkit-scrollbar-thumb {
    background: #000;
    outline: 3px solid #000;
    outline-offset: -2px;
}

.overscroll::-webkit-scrollbar-track,
.MuiMenu-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0,0,0,0.2);
} */

/* Styling the scrollbar in Firefox  */
/* .overscroll {
    scrollbar-width: thin;
    scrollbar-color: #5E77A1 #dcdfe4;
    margin-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.MuiMenu-list {
    scrollbar-width: thin !important;
    scrollbar-color: #5E77A1 #dcdfe4 !important;
    margin-right: 6px !important;
}

.overscroll:hover,
.MuiMenu-list:hover {
    scrollbar-color: #000 #dcdfe4 !important;
}

body {
    scrollbar-width: inherit;
    scrollbar-color: inherit;
} */

.MuiMenu-list label {
    word-break: break-word;
}


.innerPopup {
    max-height: calc(94vh - 56px);
    overflow-y: auto;
}

@media only screen and (max-width: 599px) {
    .innerPopup {
        margin-bottom: 24px;
    }

    .overscroll--mobNoMTop {
        margin-top: 0;
    }

    .innerPopup--mobLarge {
        max-height: 100dvh;
        margin-bottom: 0;
        padding-bottom: 24px;
    }
}
@media only screen and (max-width: 1023px) and (orientation: landscape) {
    .innerPopup {
        margin-bottom: 24px;
    }
    .innerPopup--mobLarge {
        margin-bottom: 0;
        padding-bottom: 24px;
    }
    .innerPopup {
        max-height: calc(88vh - 56px);
    }
    /* .innerPopup--mobLarge {
        max-height: 100dvh;
    } */
}
@media only screen and (max-width: 599px) {
    .innerPopup--mobLarge {
        max-height: 100dvh;
        margin-bottom: 0;
        padding-bottom: 24px;
    }
}

/* TIMEPICKER */
.MuiPickersLayout-root {
    min-width: 256px;
}
.MuiPickersLayout-root .MuiButtonBase-root {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    border-radius: 32px;
}

.MuiPickersLayout-root .MuiButtonBase-root.MuiButton-sizeMedium {
    border: 1px solid #313D53;
}

.MuiPickersLayout-root .MuiMultiSectionDigitalClock-root {
    flex-grow: 1;
}

.MuiPickersLayout-root .MuiMultiSectionDigitalClock-root .MuiButtonBase-root{
    min-width: 60px;
    border: 1px solid transparent;
}

.MuiPickersLayout-root .MuiMultiSectionDigitalClock-root .MuiButtonBase-root.Mui-selected {
    border-color: #313D53;
    background: rgba(0, 0, 0, 0.12);
    color: #313D53;
}

.walkthroughtCarousel ~ .react-multi-carousel-dot-list {
    bottom: 16px;
}

.backBtn {
    position: relative;
}

.backBtn:before {
    content: '';
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    position: absolute;
    left: -12px;
    top: -12px;
}