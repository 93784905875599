//base 
$basesize: 16px;
$base-font-size: 16;

//breakpoints
$breakpoints: ('xsmall': 375px, 'msmall': 480px, 'small': 600px, 'medium': 768px, 'large': 960px, 'xlarge': 1280px, 'xxlarge' : 1366px) !default;

//colors
$white: #ffffff;

:root {
    --white: #ffffff;
    --primary: #313D53;
}