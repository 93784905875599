@import '../starter';
.navButton {
    --size: 64px;
    --gap: -8px;
    --outline: 16px;
    button {
        width: var(--size);
        height: var(--size);
        outline: var(--outline) solid rgba($white,.88);
    }
    &--left {
        margin-left: var(--gap);
    }
    &--right {
        margin-right: var(--gap);
    }
}