:root {
  --white: #ffffff;
  --primary: #313D53;
}

.navButton {
  --size: 64px;
  --gap: -8px;
  --outline: 16px;
}
.navButton button {
  width: var(--size);
  height: var(--size);
  outline: var(--outline) solid rgba(255, 255, 255, 0.88);
}
.navButton--left {
  margin-left: var(--gap);
}
.navButton--right {
  margin-right: var(--gap);
}/*# sourceMappingURL=partnerstores.css.map */